/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "./src/css/style.css"
require("typeface-inter")

// const { registerLinkResolver } = require("gatsby-source-prismic-graphql")
// const { linkResolver } = require("./src/utils/linkResolver")

// registerLinkResolver(linkResolver)

// const addScript = url => {
//   const script = document.createElement("script")
//   script.src = url
//   script.async = false
//   document.body.appendChild(script)
// }

// export const onClientEntry = () => {
//   window.onload = () => {
//     addScript("/hassia-consent_wilhelm-reuschling.de_20201130.js")
//   }
// }
